import React from 'react';
import ScenarioSessionDemo from '../components/ScenarioManager/ScenarioSessionDemo';

/**
 * Page component for the Scenario Session feature
 */
const ScenarioSessionPage = () => {
  return <ScenarioSessionDemo />;
};

export default ScenarioSessionPage; 