import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { 
  Box, 
  Button, 
  CircularProgress, 
  Typography, 
  Paper, 
  Grid, 
  Alert, 
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Stack,
  InputAdornment,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import RestoreIcon from '@mui/icons-material/Restore';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import SortIcon from '@mui/icons-material/Sort';
import ClearIcon from '@mui/icons-material/Clear';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

// Constants for threshold values
const MIN_KWH_THRESHOLD = 0;
const MAX_KWH_THRESHOLD = 1000000000;

/**
 * ScenarioSession component for creating and editing scenario sessions
 * 
 * @param {Object} props - Component props
 * @param {string} props.ratePeriodId - The rate period ID to use for creating the scenario
 * @param {Function} props.onSessionCreated - Callback function when a session is created
 */
const ScenarioSession = ({ ratePeriodId, onSessionCreated }) => {
  // State for managing the component
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [rateCharges, setRateCharges] = useState([]);
  const [editedRateCharges, setEditedRateCharges] = useState({});
  const [modifiedFields, setModifiedFields] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('rate_code');
  const [sortDirection, setSortDirection] = useState('asc');
  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  /**
   * Sanitizes rate charges data to handle any problematic values
   * 
   * @param {Array} charges - The rate charges to sanitize
   * @returns {Array} - The sanitized rate charges
   */
  const sanitizeRateCharges = (charges) => {
    if (!charges || !Array.isArray(charges)) return [];
    
    return charges.map(charge => {
      const sanitized = { ...charge };
      
      // Handle large end_kwh_threshold values
      if (sanitized.end_kwh_threshold >= MAX_KWH_THRESHOLD) {
        sanitized.end_kwh_threshold = MAX_KWH_THRESHOLD;
        console.log(`Sanitized large end_kwh_threshold value to ${MAX_KWH_THRESHOLD} for ${sanitized.rate_code}-${sanitized.rate_class}`);
      }
      
      // Ensure numeric fields are actually numbers
      ['kwh_cost', 'start_kwh_threshold', 'end_kwh_threshold', 'service_fee', 
       'facility_charge', 'service_availability_fee', 'capacity_charge_kwh'].forEach(field => {
        if (sanitized[field] === null || sanitized[field] === undefined) {
          sanitized[field] = 0;
        } else if (typeof sanitized[field] !== 'number') {
          try {
            sanitized[field] = parseFloat(sanitized[field]) || 0;
          } catch (e) {
            sanitized[field] = 0;
          }
        }
      });
      
      // Apply threshold constraints
      if (sanitized.start_kwh_threshold < MIN_KWH_THRESHOLD) {
        sanitized.start_kwh_threshold = MIN_KWH_THRESHOLD;
        console.log(`Sanitized start_kwh_threshold to minimum value for ${sanitized.rate_code}-${sanitized.rate_class}`);
      }
      
      if (sanitized.end_kwh_threshold > MAX_KWH_THRESHOLD) {
        sanitized.end_kwh_threshold = MAX_KWH_THRESHOLD;
        console.log(`Sanitized end_kwh_threshold to maximum value for ${sanitized.rate_code}-${sanitized.rate_class}`);
      }
      
      return sanitized;
    });
  };

  /**
   * Creates a new scenario session
   */
  const createScenarioSession = async () => {
    try {
      // Set loading state and clear any previous errors
      setLoading(true);
      setError(null);
      
      // Log the request details for debugging
      console.log('Creating new scenario session for rate period ID:', ratePeriodId);
      
      // Call the API to create a new scenario - only send rate_period_id
      const response = await API.post('scenario', '/scenario/create', {
        body: {
          rate_period_id: ratePeriodId
          // Don't send session_id - the API will generate one
        }
      });
      
      // Log the response for debugging
      console.log('Created scenario session:', response);
      
      // Get the session ID from the response
      const apiSessionId = response.session_id || response.sessionId;
      
      if (!apiSessionId) {
        throw new Error('No session ID returned from API');
      }
      
      // Set the session ID from the API response
      setSessionId(apiSessionId);
      console.log('Using session ID from API:', apiSessionId);
      
      // Sanitize the rate charges data
      const sanitizedRateCharges = sanitizeRateCharges(response.rateCharges || []);
      
      // Update state with the sanitized data
      setRateCharges(sanitizedRateCharges);
      setSuccess('Scenario session created successfully');
      
      // Initialize the edited rate charges object with sanitized data
      const initialEditedCharges = {};
      sanitizedRateCharges.forEach(charge => {
        const chargeId = charge.rate_code_and_charges_id || charge.id;
        initialEditedCharges[chargeId] = { ...charge };
      });
      setEditedRateCharges(initialEditedCharges);
      
      // Initialize the modified fields tracking object
      setModifiedFields({});
      
      // Call the callback function if provided
      if (onSessionCreated) {
        // Update the response with sanitized data
        const sanitizedResponse = {
          ...response,
          rateCharges: sanitizedRateCharges
        };
        onSessionCreated(sanitizedResponse);
      }
    } catch (err) {
      // Handle errors
      console.error('Error creating scenario session:', err);
      setError(`Failed to create scenario session: ${err.message}`);
      setSessionId(null);
    } finally {
      // Reset loading state
      setLoading(false);
    }
  };

  /**
   * Handles changes to rate charge values
   * 
   * @param {string} id - The ID of the rate charge being edited
   * @param {string} field - The field being edited
   * @param {string} value - The new value
   */
  const handleRateChargeChange = (id, field, value) => {
    // Create a copy of the edited rate charges
    const updatedCharges = { ...editedRateCharges };
    
    // Get the edited charge
    const editedCharge = updatedCharges[id];
    if (!editedCharge) return;
    
    // Find the original charge by matching rate_code and rate_class
    // Also match on start_kwh_threshold if it exists to handle tiered rates
    const originalCharge = rateCharges.find(c => 
      c.rate_code === editedCharge.rate_code && 
      c.rate_class === editedCharge.rate_class &&
      (field === 'start_kwh_threshold' || field === 'end_kwh_threshold' || 
       c.start_kwh_threshold === editedCharge.start_kwh_threshold)
    );
    
    // Parse the value based on the field type
    let parsedValue = value;
    if (field === 'start_kwh_threshold' || field === 'end_kwh_threshold') {
      // For integer fields, parse as integer or empty string
      parsedValue = value === '' ? '' : parseInt(value, 10);
      
      // Apply threshold constraints
      if (field === 'start_kwh_threshold') {
        // Ensure start threshold is not less than minimum
        if (parsedValue < MIN_KWH_THRESHOLD) {
          parsedValue = MIN_KWH_THRESHOLD;
          console.log(`Adjusted start_kwh_threshold to minimum value: ${MIN_KWH_THRESHOLD}`);
        }
      } else if (field === 'end_kwh_threshold') {
        // Ensure end threshold is not greater than maximum
        if (parsedValue > MAX_KWH_THRESHOLD) {
          parsedValue = MAX_KWH_THRESHOLD;
          console.log(`Adjusted end_kwh_threshold to maximum value: ${MAX_KWH_THRESHOLD}`);
        }
      }
    } else {
      // For float fields, parse as float or empty string
      parsedValue = value === '' ? '' : parseFloat(value);
    }
    
    // Update the specified field with the new value
    updatedCharges[id] = {
      ...updatedCharges[id],
      [field]: parsedValue
    };
    
    // Update state
    setEditedRateCharges(updatedCharges);
    
    // Track modified fields
    const updatedModifiedFields = { ...modifiedFields };
    
    // Check if the value is different from the original
    const originalValue = originalCharge ? originalCharge[field] : null;
    const isModified = originalCharge && parsedValue !== originalValue;
    
    if (isModified) {
      // If modified, add to tracking
      if (!updatedModifiedFields[id]) {
        updatedModifiedFields[id] = {};
      }
      updatedModifiedFields[id][field] = true;
    } else {
      // If not modified, remove from tracking
      if (updatedModifiedFields[id]) {
        delete updatedModifiedFields[id][field];
        
        // If no more modified fields for this charge, remove the charge entry
        if (Object.keys(updatedModifiedFields[id]).length === 0) {
          delete updatedModifiedFields[id];
        }
      }
    }
    
    setModifiedFields(updatedModifiedFields);
    
    // Log the change for debugging
    console.log(`Updated ${field} for charge ${id} to ${parsedValue}`);
    console.log('Modified fields:', updatedModifiedFields);
  };

  /**
   * Resets a field to its original value
   * 
   * @param {string} id - The ID of the rate charge
   * @param {string} field - The field to reset
   */
  const resetField = (id, field) => {
    // Get the edited charge
    const editedCharge = editedRateCharges[id];
    if (!editedCharge) return;
    
    // Find the original charge by matching rate_code and rate_class
    // Also match on start_kwh_threshold if it exists to handle tiered rates
    const originalCharge = rateCharges.find(c => 
      c.rate_code === editedCharge.rate_code && 
      c.rate_class === editedCharge.rate_class &&
      (field === 'start_kwh_threshold' || field === 'end_kwh_threshold' || 
       c.start_kwh_threshold === editedCharge.start_kwh_threshold)
    );
    
    // If original charge not found, do nothing
    if (!originalCharge) return;
    
    // Get the original value, with special handling for large end_kwh_threshold values
    let originalValue = originalCharge[field];
    if (field === 'end_kwh_threshold' && originalValue > MAX_KWH_THRESHOLD) {
      originalValue = MAX_KWH_THRESHOLD;
      console.log(`Adjusted end_kwh_threshold to maximum value during reset: ${MAX_KWH_THRESHOLD}`);
    }
    
    // Create a copy of the edited rate charges
    const updatedCharges = { ...editedRateCharges };
    
    // Reset the field to its original value
    updatedCharges[id] = {
      ...updatedCharges[id],
      [field]: originalValue
    };
    
    // Update state
    setEditedRateCharges(updatedCharges);
    
    // Update modified fields tracking
    const updatedModifiedFields = { ...modifiedFields };
    if (updatedModifiedFields[id]) {
      delete updatedModifiedFields[id][field];
      
      // If no more modified fields for this charge, remove the charge entry
      if (Object.keys(updatedModifiedFields[id]).length === 0) {
        delete updatedModifiedFields[id];
      }
    }
    
    setModifiedFields(updatedModifiedFields);
    
    // Log the reset for debugging
    console.log(`Reset ${field} for charge ${id} to original value: ${originalValue}`);
  };

  /**
   * Resets all fields to their original values
   */
  const resetAllFields = () => {
    // Create a copy of the edited rate charges with original values
    const originalCharges = {};
    
    // For each edited charge, find the original and reset to those values
    Object.keys(editedRateCharges).forEach(id => {
      const editedCharge = editedRateCharges[id];
      
      // Find the original charge by matching rate_code and rate_class
      // We don't include start_kwh_threshold here because we want to reset all tiers
      const originalCharge = rateCharges.find(c => 
        c.rate_code === editedCharge.rate_code && 
        c.rate_class === editedCharge.rate_class
      );
      
      if (originalCharge) {
        // Create a copy of the original charge
        const resetCharge = { 
          ...originalCharge, 
          id: editedCharge.id,
          // Preserve the rate_code_and_charges_id if it exists
          rate_code_and_charges_id: editedCharge.rate_code_and_charges_id || originalCharge.rate_code_and_charges_id
        };
        
        // Handle threshold constraints
        if (resetCharge.start_kwh_threshold < MIN_KWH_THRESHOLD) {
          resetCharge.start_kwh_threshold = MIN_KWH_THRESHOLD;
          console.log(`Adjusted start_kwh_threshold to minimum value during reset all for ${resetCharge.rate_code}-${resetCharge.rate_class}`);
        }
        
        if (resetCharge.end_kwh_threshold > MAX_KWH_THRESHOLD) {
          resetCharge.end_kwh_threshold = MAX_KWH_THRESHOLD;
          console.log(`Adjusted end_kwh_threshold to maximum value during reset all for ${resetCharge.rate_code}-${resetCharge.rate_class}`);
        }
        
        // Keep the id from the edited charge but use all other values from original
        originalCharges[id] = resetCharge;
      } else {
        // If no original found, keep as is
        originalCharges[id] = { ...editedCharge };
      }
    });
    
    // Update state
    setEditedRateCharges(originalCharges);
    setModifiedFields({});
    
    // Close the dialog
    setResetDialogOpen(false);
    
    // Show success message
    setSuccess('All changes have been reset');
    
    // Log the reset for debugging
    console.log('Reset all fields to original values');
  };

  /**
   * Saves the changes to the rate charges
   */
  const saveChanges = async () => {
    try {
      // Set loading state and clear any previous errors
      setLoading(true);
      setError(null);
      
      // Prepare the data to be sent to the API
      const changedRateCharges = [];
      
      // Loop through all modified fields
      Object.keys(modifiedFields).forEach(chargeId => {
        const charge = editedRateCharges[chargeId];
        if (charge) {
          // Check for any problematic values before sending
          if (charge.start_kwh_threshold < MIN_KWH_THRESHOLD) {
            console.warn(`Found small start_kwh_threshold value (${charge.start_kwh_threshold}) for ${charge.rate_code}-${charge.rate_class}, adjusting to ${MIN_KWH_THRESHOLD}`);
            charge.start_kwh_threshold = MIN_KWH_THRESHOLD;
          }
          
          if (charge.end_kwh_threshold > MAX_KWH_THRESHOLD) {
            console.warn(`Found large end_kwh_threshold value (${charge.end_kwh_threshold}) for ${charge.rate_code}-${charge.rate_class}, adjusting to ${MAX_KWH_THRESHOLD}`);
            charge.end_kwh_threshold = MAX_KWH_THRESHOLD;
          }
          
          // Only include the necessary fields in the override_items
          const simplifiedCharge = {
            rate_code_and_charge_id: charge.rate_code_and_charges_id || charge.rate_code_and_charge_id,
            kwh_cost: charge.kwh_cost,
            start_kwh_threshold: charge.start_kwh_threshold,
            end_kwh_threshold: charge.end_kwh_threshold,
            service_fee: charge.service_fee,
            facility_charge: charge.facility_charge,
            service_availability_fee: charge.service_availability_fee,
            capacity_charge_kwh: charge.capacity_charge_kwh
          };
          
          changedRateCharges.push(simplifiedCharge);
        }
      });
      
      // Log the changes for debugging
      console.log('Saving changes for rate charges:', changedRateCharges);
      console.log('Request payload:', JSON.stringify({
        session_id: sessionId,
        override_items: changedRateCharges
      }, null, 2));
      
      // Call the API to save the changes
      console.log('Saving changes with session ID:', sessionId);
      const response = await API.post('scenario', '/scenario/update', {
        body: {
          session_id: sessionId,
          override_items: changedRateCharges  // API expects 'override_items' not 'rate_charges'
        }
      });
      
      // Log the response for debugging
      console.log('Saved changes:', response);
      
      // Update state with the response data
      if (response.rateCharges) {
        // Sanitize the returned rate charges
        const sanitizedRateCharges = sanitizeRateCharges(response.rateCharges);
        setRateCharges(sanitizedRateCharges);
        
        // Update the edited rate charges
        const updatedEditedCharges = {};
        sanitizedRateCharges.forEach(charge => {
          const chargeId = charge.rate_code_and_charges_id || charge.id;
          updatedEditedCharges[chargeId] = { ...charge };
        });
        setEditedRateCharges(updatedEditedCharges);
      }
      
      // Clear the modified fields
      setModifiedFields({});
      
      // Show success message
      setSuccess('Changes saved successfully');
    } catch (err) {
      // Handle errors
      console.error('Error saving changes:', err);
      setError(`Failed to save changes: ${err.message}`);
    } finally {
      // Reset loading state
      setLoading(false);
    }
  };

  /**
   * Handles closing the success message
   */
  const handleSuccessClose = () => {
    setSuccess(null);
  };

  /**
   * Handles closing the error message
   */
  const handleErrorClose = () => {
    setError(null);
  };

  /**
   * Handles search term changes
   */
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  /**
   * Clears the search term
   */
  const clearSearch = () => {
    setSearchTerm('');
  };

  /**
   * Handles sorting
   * 
   * @param {string} field - The field to sort by
   */
  const handleSort = (field) => {
    if (sortField === field) {
      // If already sorting by this field, toggle direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Otherwise, sort by this field in ascending order
      setSortField(field);
      setSortDirection('asc');
    }
  };

  /**
   * Filters and sorts the rate charges
   */
  const getFilteredAndSortedRateCharges = () => {
    // Filter the rate charges
    const filtered = rateCharges.filter(charge => {
      if (!searchTerm) return true;
      
      const searchLower = searchTerm.toLowerCase();
      return (
        (charge.rate_code && charge.rate_code.toLowerCase().includes(searchLower)) ||
        (charge.rate_class && charge.rate_class.toLowerCase().includes(searchLower))
      );
    });
    
    // Sort the filtered rate charges
    return filtered.sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      
      if (aValue === bValue) return 0;
      
      const comparison = aValue < bValue ? -1 : 1;
      return sortDirection === 'asc' ? comparison : -comparison;
    });
  };

  /**
   * Renders the rate charges table
   */
  const renderRateChargesTable = () => {
    const filteredAndSortedRateCharges = getFilteredAndSortedRateCharges();
    
    if (!rateCharges || rateCharges.length === 0) {
      return (
        <Typography variant="body2" sx={{ mt: 2 }}>
          No rate charges available.
        </Typography>
      );
    }

    // Helper function to render an editable field with modification indicator
    const renderEditableField = (charge, field, type = 'number', step = '0.01') => {
      // Find the original charge by matching rate_code and rate_class
      // Also match on start_kwh_threshold if it exists to handle tiered rates
      const originalCharge = rateCharges.find(c => 
        c.rate_code_and_charges_id === charge.rate_code_and_charges_id 
      );
      
      // Get the original value or use the current value as fallback
      const originalValue = originalCharge ? originalCharge[field] : charge[field];
      
      // Format the display value appropriately based on field type
      const chargeId = charge.rate_code_and_charges_id || charge.id;
      let displayValue = editedRateCharges[chargeId]?.[field] ?? originalValue;
      
      // For threshold fields, use more reasonable defaults if the value is extremely large
      if ((field === 'end_kwh_threshold' && displayValue > MAX_KWH_THRESHOLD) || displayValue === null) {
        if (field === 'end_kwh_threshold') {
          displayValue = MAX_KWH_THRESHOLD; // Default to MAX_KWH_THRESHOLD for unreasonably large end thresholds
          
          // Update the edited value to match what we're displaying
          if (editedRateCharges[chargeId]) {
            const updatedCharges = { ...editedRateCharges };
            updatedCharges[chargeId] = {
              ...updatedCharges[chargeId],
              [field]: displayValue
            };
            // We're updating this in the render function, which isn't ideal,
            // but it's a one-time correction for bad data
            setTimeout(() => setEditedRateCharges(updatedCharges), 0);
          }
        } else if (field === 'start_kwh_threshold') {
          displayValue = MIN_KWH_THRESHOLD; // Default to MIN_KWH_THRESHOLD for null start thresholds
          
          // Update the edited value to match what we're displaying
          if (editedRateCharges[chargeId]) {
            const updatedCharges = { ...editedRateCharges };
            updatedCharges[chargeId] = {
              ...updatedCharges[chargeId],
              [field]: displayValue
            };
            setTimeout(() => setEditedRateCharges(updatedCharges), 0);
          }
        } else if (typeof displayValue !== 'number' || isNaN(displayValue)) {
          displayValue = 0; // Default to 0 for non-numeric values
        }
      }
      
      // Check if the current value is different from the original
      const isModified = modifiedFields[chargeId]?.[field];
      
      // Log for debugging
      if (field === 'end_kwh_threshold' && displayValue > MAX_KWH_THRESHOLD) {
        console.log(`Large threshold value for ${charge.rate_code}-${charge.rate_class}: ${displayValue}`);
      }
      
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            size="small"
            type={type}
            value={displayValue}
            onChange={(e) => handleRateChargeChange(chargeId, field, e.target.value)}
            InputProps={{ 
              inputProps: { 
                step: step,
                min: type === 'number' ? 0 : undefined
              },
              sx: { 
                bgcolor: isModified ? 'rgba(255, 152, 0, 0.1)' : 'transparent',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: isModified ? 'orange' : undefined
                }
              }
            }}
            sx={{ width: '120px' }}
          />
          {isModified && (
            <Tooltip title={`Original value: ${originalValue}`}>
              <Button 
                size="small" 
                variant="text" 
                color="warning"
                onClick={() => resetField(chargeId, field)}
                sx={{ minWidth: '30px', p: 0 }}
              >
                <RestoreIcon fontSize="small" />
              </Button>
            </Tooltip>
          )}
        </Stack>
      );
    };

    // Helper function to render a sortable column header
    const renderSortableHeader = (field, label) => {
      return (
        <TableCell>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              cursor: 'pointer' 
            }}
            onClick={() => handleSort(field)}
          >
            {label}
            {sortField === field && (
              <SortIcon 
                fontSize="small" 
                sx={{ 
                  ml: 0.5,
                  transform: sortDirection === 'desc' ? 'rotate(180deg)' : 'none'
                }} 
              />
            )}
          </Box>
        </TableCell>
      );
    };

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <TextField
            placeholder="Search by rate code or class"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={clearSearch}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{ width: '300px' }}
          />
          
          <Box>
            <Chip 
              icon={<FilterListIcon />} 
              label={`${filteredAndSortedRateCharges.length} of ${rateCharges.length} items`} 
              variant="outlined" 
              color="primary"
              sx={{ mr: 1 }}
            />
            
            {hasModifications() && (
              <Chip 
                icon={<RestartAltIcon />} 
                label="Reset All Changes" 
                variant="outlined" 
                color="warning"
                onClick={() => setResetDialogOpen(true)}
              />
            )}
          </Box>
        </Box>
        
        <TableContainer component={Paper} sx={{ mt: 1, maxHeight: 600 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {renderSortableHeader('rate_code', 'Rate Code')}
                {renderSortableHeader('rate_class', 'Rate Class')}
                {renderSortableHeader('kwh_cost', 'KWH Cost')}
                {renderSortableHeader('start_kwh_threshold', 'Start KWH Threshold')}
                {renderSortableHeader('end_kwh_threshold', 'End KWH Threshold')}
                {renderSortableHeader('service_fee', 'Service Fee')}
                {renderSortableHeader('facility_charge', 'Facility Charge')}
                {renderSortableHeader('service_availability_fee', 'Service Availability Fee')}
                {renderSortableHeader('capacity_charge_kwh', 'Capacity Charge KWH')}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedRateCharges.map((charge) => (
                <TableRow key={charge.rate_code_and_charges_id || charge.id}>
                  <TableCell>{charge.rate_code}</TableCell>
                  <TableCell>{charge.rate_class}</TableCell>
                  <TableCell>
                    {renderEditableField(charge, 'kwh_cost')}
                  </TableCell>
                  <TableCell>
                    {renderEditableField(charge, 'start_kwh_threshold', 'number', '1')}
                  </TableCell>
                  <TableCell>
                    {renderEditableField(charge, 'end_kwh_threshold', 'number', '1')}
                  </TableCell>
                  <TableCell>
                    {renderEditableField(charge, 'service_fee')}
                  </TableCell>
                  <TableCell>
                    {renderEditableField(charge, 'facility_charge')}
                  </TableCell>
                  <TableCell>
                    {renderEditableField(charge, 'service_availability_fee')}
                  </TableCell>
                  <TableCell>
                    {renderEditableField(charge, 'capacity_charge_kwh')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  /**
   * Checks if there are any modified fields
   */
  const hasModifications = () => {
    return Object.keys(modifiedFields).length > 0;
  };

  /**
   * Renders the reset confirmation dialog
   */
  const renderResetDialog = () => {
    return (
      <Dialog
        open={resetDialogOpen}
        onClose={() => setResetDialogOpen(false)}
      >
        <DialogTitle>Reset All Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset all changes? This will revert all modified fields to their original values.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResetDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={resetAllFields} color="warning" variant="contained">
            Reset All
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  /**
   * Effect to log session ID changes
   */
  useEffect(() => {
    if (sessionId) {
      console.log('Current session ID:', sessionId);
    }
  }, [sessionId]);

  /**
   * Effect to sanitize rate charges when they change
   */
  useEffect(() => {
    if (rateCharges.length > 0) {
      // Check for any problematic values
      const hasProblematicValues = rateCharges.some(charge => 
        charge.start_kwh_threshold < MIN_KWH_THRESHOLD ||
        charge.end_kwh_threshold > MAX_KWH_THRESHOLD ||
        charge.kwh_cost === null ||
        charge.service_fee === null ||
        charge.facility_charge === null ||
        charge.service_availability_fee === null ||
        charge.capacity_charge_kwh === null
      );
      
      // If there are problematic values, sanitize the data
      if (hasProblematicValues) {
        console.log('Found problematic values in rate charges, sanitizing...');
        const sanitizedRateCharges = sanitizeRateCharges(rateCharges);
        setRateCharges(sanitizedRateCharges);
        
        // Update edited rate charges as well
        const updatedEditedCharges = {};
        sanitizedRateCharges.forEach(charge => {
          const chargeId = charge.rate_code_and_charges_id || charge.id;
          
          // If we already have an edited version, merge the sanitized values
          if (editedRateCharges[chargeId]) {
            updatedEditedCharges[chargeId] = {
              ...charge,
              ...editedRateCharges[chargeId],
              // Always use the sanitized value for end_kwh_threshold if it was problematic
              end_kwh_threshold: charge.end_kwh_threshold
            };
          } else {
            updatedEditedCharges[chargeId] = { ...charge };
          }
        });
        
        setEditedRateCharges(updatedEditedCharges);
      }
    }
  }, [rateCharges]);

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Typography variant="h6" gutterBottom>
        Scenario Session
      </Typography>
      
      {!sessionId ? (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={createScenarioSession}
          disabled={loading}
        >
          Create Scenario Session
        </Button>
      ) : (
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Session ID: {sessionId}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Rate Period ID: {ratePeriodId}
          </Typography>
          <Typography variant="body2" gutterBottom>
            Rate Charges: {rateCharges.length} items
          </Typography>
          
          {renderRateChargesTable()}
          
          <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              disabled={loading || !hasModifications()}
              sx={{ mt: 2 }}
              onClick={saveChanges}
            >
              Save Changes
            </Button>
            
            {hasModifications() && (
              <Typography variant="body2" color="warning.main" sx={{ mt: 3 }}>
                * Orange highlighted fields have been modified from their original values
              </Typography>
            )}
          </Box>
        </Box>
      )}
      
      {loading && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress />
        </Box>
      )}
      
      <Snackbar open={!!success} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
      
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      
      {renderResetDialog()}
    </Paper>
  );
};

export default ScenarioSession; 