import React, { useState } from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Layout from './pages/Layout';
import Home/* , {loader as homeLoader } */ from './pages/Home';
import Preferences, { loader as preferencesLoader } from './pages/Preferences';
import CostOfServiceSupplierAnalysis, { loader as costOfServiceSupplierAnalysisLoader } from './pages/CostOfService/SupplierAnalysis';
import CostOfServiceBillManager, { loader as costOfServiceBillManagerLoader } from './pages/CostOfService/BillManager';
import RevenueFromServiceCustomerOverview, { loader as revenueFromServiceCustomerOverviewLoader } from './pages/RevenueFromService/CustomerOverview';
import RevenueFromServiceCustomerAnalysis, { loader as revenueFromServiceCustomerAnalysisLoader } from './pages/RevenueFromService/CustomerAnalysis';
import RevenueFromServiceBillManager, { loader as revenueFromServiceBillManagerLoader } from './pages/RevenueFromService/BillManager';
import PowerPNLWhatIfScenario, { loader as powerPNLWhatIfScenarioLoader } from './pages/PowerPNL/WhatIfScenario2';
import PowerPNLRateAnalysis, { loader as powerPNLRateAnalysisLoader } from './pages/PowerPNL/RateAnalysis';
import ErrorPage from './pages/Error'
import Admin, { loader as adminLoader } from './pages/Admin';
import ScenarioSessionPage from './pages/ScenarioSessionPage';

const costOfService = {
  path: 'costOfService',
  children: [
    {
      path: 'supplierAnalysis',
      loader: costOfServiceSupplierAnalysisLoader,
      element: <CostOfServiceSupplierAnalysis />
    },
    {
      path: 'billManager',
      loader: costOfServiceBillManagerLoader,
      element: <CostOfServiceBillManager />
    }
  ]
};

const revenueFromService = {
  path: 'revenueFromService',
  children: [
    {
      path: 'customerBaseOverview',
      loader: revenueFromServiceCustomerOverviewLoader,
      element: <RevenueFromServiceCustomerOverview />
    },
    {
      path: 'customerAnalysis',
      loader: revenueFromServiceCustomerAnalysisLoader,
      element: <RevenueFromServiceCustomerAnalysis />
    },
    {
      path: 'billManager',
      loader: revenueFromServiceBillManagerLoader,
      element: <RevenueFromServiceBillManager />
    }
  ]
};

const powerPNL = {
  path: 'powerPNL',
  children: [
    {
      path: 'whatIfScenario2',
      loader: powerPNLWhatIfScenarioLoader,
      element: <PowerPNLWhatIfScenario />
    },
    {
      path: 'rateAnalysis',
      loader: powerPNLRateAnalysisLoader,
      element: <PowerPNLRateAnalysis />
    },
    {
      path: 'scenarioSession',
      element: <ScenarioSessionPage />
    }
  ]
};

const admin = {
  path: 'admin',
  loader: adminLoader,
  element: <Admin />
};

const preferences = {
  path: 'preferences',
  loader: preferencesLoader,
  element: <Preferences />
}

export const routingConfig = [
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: 'Preferences', element: <Preferences /> },
      costOfService,
      revenueFromService,
      powerPNL,
      admin,
      preferences,
    ]
  }
];

const AppRouter = () => {
  const [router] = useState(createBrowserRouter(routingConfig));

  return (
    <RouterProvider router={router} />
  );
};

export default AppRouter;
