import React from 'react';
import { Box, Button, Typography, CircularProgress, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from '../../assets/images/gridFinLogoLight.png';

// Styled components
const AuthWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const LogoImage = styled('img')({
  maxWidth: '240px',
  marginBottom: '2rem',
});

const LoginButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(1, 4),
}));

/**
 * AuthContainer component for handling authentication UI
 * Displays loading state or login button based on authentication state
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isLoading - Whether authentication is initializing
 * @param {Function} props.onSignIn - Sign in function
 * @param {string} props.error - Authentication error message
 * @returns {JSX.Element} Auth container component
 */
const AuthContainer = ({ isLoading, onSignIn, error }) => {
  if (isLoading) {
    return (
      <AuthWrapper>
        <CircularProgress size={50} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Initializing authentication...
        </Typography>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      <Container maxWidth="sm">
        <Box textAlign="center">
          {/* Replace with your actual logo */}
          {Logo && <LogoImage src={Logo} alt="GridFin Logo" />}
          
          <Typography variant="h4" component="h1" gutterBottom>
            GridFin Dashboard
          </Typography>
          
          <Typography variant="body1" color="text.secondary" paragraph>
            Please sign in to access the application
          </Typography>
          
          <LoginButton 
            variant="contained" 
            color="primary" 
            size="large" 
            onClick={onSignIn}
          >
            Sign In
          </LoginButton>
          
          {error && (
            <Typography 
              variant="body2" 
              color="error" 
              sx={{ mt: 2 }}
            >
              {error.message || 'An error occurred during authentication'}
            </Typography>
          )}
        </Box>
      </Container>
    </AuthWrapper>
  );
};

export default AuthContainer; 