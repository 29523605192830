import React, { createContext, useContext, useEffect, useState } from 'react';
import { Auth, Hub, API } from 'aws-amplify';

import {
  setStorageItem,
  getStorageItem
} from '../util/AppStorage';

const AuthContext = createContext();
const accessList = {
  Home: 'Home',
  CostOfService: 'CostOfService',
  RevenueFromService: 'RevenueFromService',
  PowerPNL: 'PowerPNL',
  Admin: 'Admin',
  CreateTenant: 'CreateTenant',
  Preferences: 'Preferences',
};

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [clientConfig, setClientConfig] = useState(null);
  const [reloadClientConfig, setReloadClientConfig] = useState(false);
  const [authInitialized, setAuthInitialized] = useState(false);

  const resetState = () => {
    setUser(null);
    setUserEmail(null);
    setUserGroups([]);
    setStorageItem('userGroups', []);
    setClientId(null);
    setClientConfig(null);
  };

  useEffect(() => {
    const getClientConfig = async () => {
      if (user) {
        try {
          const {
            signInUserSession: {
              idToken: {
                jwtToken,
                payload: {
                  sub: payloadSub
                }
              }
            }
          } = user;

          const params = {
            headers: {},
            response: true,
            queryStringParameters: {
              jwtToken: jwtToken,
              payloadSub: payloadSub
            }
          }

          console.log('getClientConfig - Request params:', params);
          const data = await API.get('GridFin', '/common/client/properties', params);
          
          // Override with local preferences
          const localGridfinTheme = localStorage.getItem('gridfinTheme');
          if (localGridfinTheme) {
            data.data.theme.gridfin.name = localGridfinTheme;
          } else {
            // Store it so that we can apply theme before getting config data
            localStorage.setItem('gridfinTheme', data.data.theme.gridfin.name);
          }
          
          const localgridfinashboardTheme = localStorage.getItem('gridfinashboardTheme');
          if (localgridfinashboardTheme) {
            data.data.theme.quickSight.themeArn = localgridfinashboardTheme;
          }
          
          const localCacheEnabled = localStorage.getItem('gridfinCacheEnabled');
          if (localCacheEnabled) {
            data.data.cache.enabled = (localCacheEnabled === 'true');
          }
          
          const localCacheTTL = localStorage.getItem('gridfinCacheTTL');
          if (localCacheTTL) {
            data.data.cache.ttl = parseInt(localCacheTTL, 10);
          }

          setClientConfig(data.data);
        } catch (error) {
          console.error('Error getting client config:', error);
        }
      }
    };
    
    if (user) {
      getClientConfig();
    }
  }, [user, reloadClientConfig]);

  useEffect(() => {
    // Function to get authenticated user data and update state
    const getUserData = async () => {
      try {
        // Get current authenticated user from Amplify Auth
        const userData = await Auth.currentAuthenticatedUser();
        console.debug('AuthContext: Got authenticated user', userData.username);
        setUser(userData);
        
        // Get user attributes to extract email and custom attributes
        let userAttributes;
        try {
          // For Cognito Hosted UI, use standard attribute retrieval
          userAttributes = await Auth.userAttributes(userData);
          
          // Get user email from attributes
          const email = userAttributes.find((attr) => attr.getName() === 'email');
          if (email) {
            setUserEmail(email.getValue());
          }
          
          // Get custom attribute clientId if it exists
          const client = userAttributes.find((attr) => attr.getName() === 'custom:tenant');
          if (client) {
            setClientId(client.getValue());
          } else {
            setClientId(null);
          }
        } catch (attributeError) {
          console.error('Error fetching user attributes:', attributeError);
          // Fallback to get email from tokens if attribute fetch fails
          if (userData.signInUserSession?.idToken?.payload?.email) {
            setUserEmail(userData.signInUserSession.idToken.payload.email);
          }
        }
        
        // Get user groups from access token payload
        const groups = userData.signInUserSession?.accessToken?.payload["cognito:groups"] || [];
        setUserGroups(groups);
        setStorageItem('userGroups', groups);
        
        // Set auth as initialized
        setAuthInitialized(true);
      } catch (error) {
        console.error('Error getting user data:', error);
        resetState();
        setAuthInitialized(true);
      }
    };

    // Set up auth listener to respond to auth events
    const authListener = async (data) => {
      console.debug(`AuthContext: Auth event: ${data.payload.event}`);
      
      switch (data.payload.event) {
        case 'signIn':
        case 'cognitoHostedUI':
        case 'tokenRefresh':
          getUserData();
          break;
        case 'signOut':
          resetState();
          break;
        default:
          break;
      }
    };
    
    // Initialize user data
    getUserData();
    
    // Set up Hub listener for auth events
    const hubListenerCancelToken = Hub.listen('auth', authListener);

    // Clean up Hub listener on unmount
    return () => hubListenerCancelToken();
  }, []);

  // Sign out function
  const signOut = async () => {
    try {
      console.debug('AuthContext: Signing out user');
      await Auth.signOut();
      resetState();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Access control arrays for different parts of the app
  const costofService = [
    'gridfin_admin',
    'gridfin_clientadmin',
    'gridfin_support',
    'gridfin_analyst',
    'gridfin_executive',
    'gridfin_manager',
  ];
  
  const revenueFromService = [
    'gridfin_admin',
    'gridfin_clientadmin',
    'gridfin_support',
    'gridfin_analyst',
    'gridfin_executive',
    'gridfin_manager',
  ];
  
  const powerPNL = [
    'gridfin_admin',
    'gridfin_clientadmin',
    'gridfin_support',
    'gridfin_analyst',
    'gridfin_executive',
    'gridfin_manager',
  ];
  
  const admin = [
    'gridfin_admin',
    'gridfin_clientadmin',
    'gridfin_manager',
  ];

  // Check if user has access to a specific area
  const hasAccess = (access) => {
    let groups = userGroups;
    if (!groups || groups.length === 0) {
      groups = getStorageItem('userGroups');
    }

    switch (access) {
      case accessList.Home:
      case accessList.Preferences:
        return true;
      case accessList.CostOfService:
        return (groups?.some(item => costofService.includes(item)));
      case accessList.RevenueFromService:
        return (groups?.some(item => revenueFromService.includes(item)));
      case accessList.PowerPNL:
        return (groups?.some(item => powerPNL.includes(item)));
      case accessList.Admin:
        return (groups?.some(item => admin.includes(item)));
      case accessList.CreateTenant:
        return (groups?.some(item => item === 'gridfin_admin'));
      default:
        return false;
    }
  };

  const setGridfinTheme = (value) => {
    if (clientConfig === null) {
      return;
    }
    const data = {
      ...clientConfig,
      theme: {
        ...clientConfig.theme,
        gridfin: {
          ...clientConfig.theme.gridfin,
          name: value
        }
      }
    };
    setClientConfig(data);
  };

  const setGridfinThemeDashboard = (value) => {
    if (clientConfig === null) {
      return;
    }
    const data = {
      ...clientConfig,
      theme: {
        ...clientConfig.theme,
        quickSight: {
          ...clientConfig.theme.quickSight,
          themeArn: value
        }
      }
    };
    setClientConfig(data);
  };

  const setGridfinCache = (value) => {
    const data = {
      ...clientConfig,
      cache: { ...value }
    };
    setClientConfig(data);
  };

  const reloadConfig = () => {
    setReloadClientConfig(!reloadClientConfig);
  };

  // Context value to be provided to consumers
  const contextValue = {
    user,
    userEmail,
    userGroups,
    clientId,
    clientConfig,
    authInitialized,
    signOut,
    accessList,
    hasAccess,
    setGridfinTheme,
    setGridfinThemeDashboard,
    setGridfinCache,
    reloadConfig
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthContextProvider');
  }
  return context;
};
