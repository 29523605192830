import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Divider, Chip } from '@mui/material';
import { ScenarioSession } from './index';

/**
 * Demo component for showcasing the ScenarioSession component
 */
const ScenarioSessionDemo = () => {
  const [ratePeriodId, setRatePeriodId] = useState('');
  const [showSession, setShowSession] = useState(false);
  const [sessionData, setSessionData] = useState(null);

  /**
   * Handles changes to the rate period ID input
   */
  const handleRatePeriodIdChange = (e) => {
    setRatePeriodId(e.target.value);
  };

  /**
   * Handles the form submission
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSession(true);
  };

  /**
   * Handles when a session is created
   */
  const handleSessionCreated = (data) => {
    console.log('Session created:', data);
    // Extract the session ID from the response
    const sessionId = data.session_id || data.sessionId;
    console.log('Session ID from API:', sessionId);
    setSessionData(data);
  };

  /**
   * Renders the session information
   */
  const renderSessionInfo = () => {
    if (!sessionData) return null;

    return (
      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Session Information
        </Typography>
        
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" color="primary" fontWeight="bold">
            Session ID: {sessionData.session_id || sessionData.sessionId}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
          <Chip 
            label={`Rate Period ID: ${sessionData.rate_period_id || sessionData.ratePeriodId}`} 
            color="secondary" 
            variant="outlined" 
          />
          <Chip 
            label={`Rate Charges: ${sessionData.rateCharges?.length || 0}`} 
            color="info" 
            variant="outlined" 
          />
        </Box>
        
        <Divider sx={{ my: 2 }} />
        
        <Typography variant="subtitle2" gutterBottom>
          Debug Information
        </Typography>
        
        <Box 
          component="pre" 
          sx={{ 
            bgcolor: '#f5f5f5', 
            p: 2, 
            borderRadius: 1,
            overflow: 'auto',
            maxHeight: '300px',
            fontSize: '0.75rem'
          }}
        >
          {JSON.stringify(sessionData, null, 2)}
        </Box>
      </Paper>
    );
  };

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Scenario Session Demo
      </Typography>
      
      <Typography variant="body1" paragraph>
        This demo allows you to create a scenario session and edit rate charges.
        Enter a rate period ID to get started.
      </Typography>
      
      {!showSession ? (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Enter Rate Period ID
          </Typography>
          
          <form onSubmit={handleSubmit}>
            <TextField
              label="Rate Period ID"
              variant="outlined"
              fullWidth
              value={ratePeriodId}
              onChange={handleRatePeriodIdChange}
              required
              sx={{ mb: 2 }}
              helperText="Enter a valid rate period ID to create a scenario session"
            />
            
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              disabled={!ratePeriodId}
            >
              Continue
            </Button>
          </form>
        </Paper>
      ) : (
        <>
          <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              variant="outlined" 
              color="secondary"
              onClick={() => {
                setShowSession(false);
                setSessionData(null);
              }}
            >
              Back to Rate Period Selection
            </Button>
          </Box>
          
          <ScenarioSession 
            ratePeriodId={ratePeriodId}
            onSessionCreated={handleSessionCreated}
          />
          
          {renderSessionInfo()}
        </>
      )}
    </Box>
  );
};

export default ScenarioSessionDemo; 