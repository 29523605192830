import { Amplify } from 'aws-amplify';
import React from 'react';
import '@aws-amplify/ui-react/styles.css';

import './App.css';
import AppRouter from './AppRouter';
import { AppLocalConfig } from './AppLocalConfig';
import { AuthContextProvider } from './store/AuthContext';
import AuthContainer from './components/UI/AuthContainer';
import awsExports from './aws-exports';
import useAuth from './util/useAuth';

// Configure Amplify with modified AWS exports
const modifiedAwsExports = {
  ...awsExports,
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:6f3e4cea-0785-4461-93fc-f07517671f4f",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_1MW8EYiDl",
  "aws_user_pools_web_client_id": "330jquc3sq016lidei9i2rlp38",
  "oauth": {
    "domain": "sparkmeter.dev.auth.spk.io",
    // Ensures the redirected URL after sign-in goes back to our app
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    responseType: 'code', // Authorization code grant - recommended for production
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'] // OAuth scopes
  }
}

Amplify.configure(modifiedAwsExports);

/**
 * Main App component
 * Handles authentication flow and renders appropriate UI
 */
const App = () => {
  // Use our custom authentication hook
  const { isAuthenticated, isInitializing, authError, signIn } = useAuth();

  // If not authenticated, show auth container
  if (!isAuthenticated) {
    return (
      <AuthContainer 
        isLoading={isInitializing}
        onSignIn={signIn}
        error={authError}
      />
    );
  }

  // If user is authenticated, render the app
  return (
    <AuthContextProvider>
      <AppLocalConfig>
        <AppRouter />
      </AppLocalConfig>
    </AuthContextProvider>
  );
};

export default App;
