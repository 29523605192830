
import WhatIfScenarioContent from "../../components/PowerPNL/WhatIfScenario/WhatIfScenarioContent";
import { APIDataLoader } from "../../util/APIDataLoader";

function WhatIfScenario2() {
    return (
        <WhatIfScenarioContent />
    )
}

export default WhatIfScenario2;

export async function loader() {;
    const promises = await Promise.all([
        APIDataLoader('rateSelector'),
        APIDataLoader('rateCalcDefault'),
        APIDataLoader('supplierCalcDefault'),
        APIDataLoader('supplierSelector')
    ]);

    return {
        rateClassesData: promises[0],
        rateCalcDefaultData: promises[1],
        supplierCalcDefaultData: promises[2],
        supplierFilterData: promises[3]
    };
}
