import { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';

/**
 * Custom hook for managing authentication with Cognito Hosted UI
 * Provides authentication state, user data, and authentication methods
 * 
 * @returns {Object} Authentication state and methods
 */
const useAuth = () => {
  // Authentication state
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [authError, setAuthError] = useState(null);
  
  /**
   * Process authentication after OAuth redirect
   */
  const handleOAuthResponse = async () => {
    try {
      // Check if current URL contains auth code or tokens
      const currentUrl = window.location.href;
      if (currentUrl.includes('code=') || currentUrl.includes('access_token=')) {
        console.debug('OAuth redirect detected, processing authentication...');
        await Auth.currentAuthenticatedUser();
      }
    } catch (error) {
      console.error('Error handling OAuth response:', error);
      setAuthError(error);
    }
  };

  /**
   * Check if user is authenticated and update auth state
   */
  const checkAuthState = async () => {
    try {
      // Get current authenticated user from Amplify Auth
      const userData = await Auth.currentAuthenticatedUser();
      console.debug('User authenticated:', userData.username);
      setUser(userData);
      setIsAuthenticated(true);
      setAuthError(null);
    } catch (error) {
      console.debug('No authenticated user found');
      setUser(null);
      setIsAuthenticated(false);
    } finally {
      setIsInitializing(false);
    }
  };

  /**
   * Sign in using Cognito Hosted UI
   */
  const signIn = () => {
    // Redirect to Cognito Hosted UI
    Auth.federatedSignIn({
      provider: 'COGNITO',
      customState: 'gridfin-app-state',
      scope: 'aws.cognito.signin.user.admin email openid profile'
    });
  };

  /**
   * Sign out current user
   */
  const signOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Error signing out:', error);
      setAuthError(error);
    }
  };

  // Set up authentication event listeners and initialize auth state
  useEffect(() => {
    // Check initial auth state
    checkAuthState();
    
    // Handle OAuth redirects on page load
    handleOAuthResponse();

    // Listen for auth events
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      console.debug(`Auth event: ${event}`);
      
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          // User signed in
          checkAuthState();
          break;
        case 'signOut':
          // User signed out
          setUser(null);
          setIsAuthenticated(false);
          break;
        case 'tokenRefresh':
          // Token refresh succeeded
          console.debug('Token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          // Token refresh failed
          console.error('Token refresh failed', data);
          setAuthError(data);
          break;
        case 'cognitoHostedUI_failure':
          // Hosted UI failed
          console.error('Cognito Hosted UI sign-in failed', data);
          setAuthError(data);
          break;
        default:
          break;
      }
    });

    // Clean up hub listener on unmount
    return unsubscribe;
  }, []);

  // Return authentication state and methods
  return {
    user,
    isAuthenticated,
    isInitializing,
    authError,
    signIn,
    signOut,
    checkAuthState
  };
};

export default useAuth; 