import { API, Auth } from 'aws-amplify';
import {
    setStorageItem,
    getStorageItem
} from './AppStorage';
import {
    cacheConfig,
    themeConfig,
} from '../AppLocalConfig';

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

export async function APIDataLoader(queryName, queryType = '/common/query') {
    try {
        let apiFilterData = null;
        if (cacheConfig?.enabled) {
            apiFilterData = getStorageItem(queryName);
        }
        if (!apiFilterData) {
            apiFilterData = await getAPIData(queryName, queryType);

            if (apiFilterData && apiFilterData.data.error) {
                console.log('error message: ' + apiFilterData.request.response);
                throw new Response(JSON.stringify({ message: apiFilterData.request.response }), {
                    status: apiFilterData.status,
                });
            }
            if (apiFilterData.status !== 200) {
                throw new Response(JSON.stringify({ message: 'Could not fetch data.' }), {
                    status: apiFilterData.status,
                });
            }
            if (cacheConfig?.enabled) {
                setStorageItem(queryName, apiFilterData, cacheConfig.ttl);
            }
        }

        return apiFilterData;
    }
    catch (err) {
        console.log('exception: ' + err);
        throw new Error('Could not fetch data. Exception: ' + err);
    }
}

const getAPIData = async (queryName, queryType = '/common/query') => {
    const data = await Auth.currentSession();
    const jwtToken = data.idToken.jwtToken;
    const payloadSub = data.idToken.payload.sub;

    const params = {
        headers: {},
        response: true,
        queryStringParameters: {
            jwtToken: jwtToken,
            payloadSub: payloadSub,
            query: queryName
        }
    }

    return await API.get('GridFin', queryType, params);
};

export function ConstructFilterParams(filterParam) {
    let filterParams = [{
        Name: 'sourceHost',
        Values: window.location.host,
    }];
    Object.keys(filterParam).forEach((keyName) => {
        if(keyName === 'startDate') {
            filterParams.push({
                Name: 'startDate',
                Values: filterParam.startDate?.startOf('day')?.startOf('month')?.format()
            });
        } else if(keyName === 'endDate') {
            filterParams.push({
                Name: 'endDate',
                Values: filterParam.endDate?.endOf('day')?.endOf('month').subtract(1, 'day')?.format()
            });
        } else {
            filterParams.push({
                Name: keyName,
                Values: filterParam[keyName]
            });
        }
    });
    // console.log(filterParams);
    return filterParams;
};

export async function APIDashboardDataLoader(dashboardName, filterParam, setLoading, containerName = "dashboardContainer") {
    var embedURL;
    try {
        const startTimeTotal = performance.now();
        const startTimeURL = performance.now();
        const quicksight = await getDashboardData(dashboardName);
        const urlTime = performance.now() - startTimeURL;

        if (quicksight.data.err)
            console.log('error message: ' + quicksight.data.err.message);

        embedURL = quicksight.data.embedUrl.EmbedUrl;
        const containerDiv = document.getElementById(containerName);
        containerDiv.innerHTML = "";

        const {
            createEmbeddingContext,
        } = QuickSightEmbedding;

        const embeddingContext = await createEmbeddingContext({
            onChange: (changeEvent, metadata) => {
                // console.log('Context received a change', changeEvent, metadata);
            },
        });

        const frameOptions = {
            url: embedURL,
            container: containerDiv,
            height: "700px",
            width: "100%",
            withIframePlaceholder: true,
            resizeHeightOnSizeChangedEvent: true,
            onChange: (changeEvent, _) => {
                switch (changeEvent.eventName) {
                    case 'FRAME_STARTED': {
                        setLoading && setLoading(true);
                        break;
                    }
                    case 'FRAME_MOUNTED': {
                        break;
                    }
                    case 'FRAME_LOADED': {
                        break;
                    }
                    default:
                        break;
                }
            },
        };

       
        console.log('[APIDataLoader] Filter parameters:', JSON.stringify(filterParam, null, 2)) 
        let contentOptions = {
            parameters: filterParam,
            toolbarOptions: {
                export: false,
                undoRedo: false, // use this option to show or hide the undo and redo buttons
                reset: false, // use this option to show or hide the  reset button
            },
            sheetOptions: {},
            attributionOptions: {
                overlayContent: false,
            },
            onMessage: async (messageEvent, _) => {
                switch (messageEvent.eventName) {
                    case 'CONTENT_LOADED': {
                        const endTime = performance.now();
                        const loadTime = endTime - startTimeDashboard;
                        const totalTime = endTime - startTimeTotal;
                        const perfData = {
                            Dashboard: dashboardName,
                            Total: totalTime,
                            GetURL: urlTime,
                            LoadData: loadTime,
                        };
                        console.log(JSON.stringify(perfData, null, 2));
                        setLoading && setLoading(false);
                        break;
                    }
                    case 'ERROR_OCCURRED': {
                        setLoading && setLoading(false);
                        break;
                    }
                    case 'PARAMETERS_CHANGED': {
                        break;
                    }
                    case 'SELECTED_SHEET_CHANGED': {
                        setLoading && setLoading(false);
                        break;
                    }
                    case 'SIZE_CHANGED': {
                        break;
                    }
                    case 'MODAL_OPENED': {
                        window.scrollTo({
                            top: 0 // iframe top position
                        });
                        break;
                    }
                    default:
                        break;
                }
            },
        };
        let themeArn = null;

        // If client does not have a specific QuickSight theme and then use
        // GridFin-Light or GridFin-Dark
        if (themeConfig?.quickSight?.themeArn) {
            themeArn = themeConfig?.quickSight?.themeArn;
        } else if (themeConfig?.gridfin?.name === 'dark') {
            themeArn = 'arn:aws:quicksight:us-east-1:172077400549:theme/dd605e71-a66c-4870-9b26-d4c39a61f3f5';
        } else if (themeConfig?.gridfin?.name === 'light') {
            themeArn = 'arn:aws:quicksight:us-east-1:172077400549:theme/374f63d7-6de1-4aad-a619-2ce4afb132ad';
        }
        if (themeArn) {
            contentOptions = {
                ...contentOptions,
                themeOptions: {
                    themeArn: themeArn
                }
            };
        }

        const startTimeDashboard = performance.now();
        await embeddingContext.embedDashboard(frameOptions, contentOptions);
    }
    catch (err) {
        console.log('getQuickSightDashboardEmbedURL-exception: ' + err);
        setLoading && setLoading(false);
    }
}

const getDashboardData = async (dashboardName) => {
    const data = await Auth.currentSession();
    // Log full session data for debugging
    console.log('getDashboardData - Current session data:', data);
    // Extract token and user info from current session
    console.log('getDashboardData - Extracting session data for dashboard:', dashboardName);
    const jwtToken = data.idToken.jwtToken;
    const payloadSub = data.idToken.payload.sub;
    const email = data.idToken.payload.email;

    console.log('getDashboardData - User info:', {
        sub: payloadSub,
        email: email
    });

    // Prepare API request parameters
    const params = {
        headers: {},
        response: true,
        queryStringParameters: {
            jwtToken: jwtToken,
            payloadSub: payloadSub,
            email: email,
            dashboard: dashboardName
        }
    }
    console.log('getDashboardData - Request params prepared for dashboard API call');

    return API.get('GridFin', '/common/dashboard', params);
};